
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import moment from 'moment';
import BaseReport from '@/components/reports-v2/BaseReport.vue';
import gdbx from '@/store/modules/gdbx';
import DataInfo from '@/components/reports-v2/components/elements/DataInfo.vue';

@Component({
	components: { DataInfo },
})
export default class AmountDisplayer extends Vue {
	@Prop({ default: false }) public readonly unfocused!: boolean;
	@Prop({ default: false }) public readonly isShowCurrencySymbol!: boolean;
	@Prop({ default: false }) public readonly isShowPercentage!: boolean;
	@Prop({ default: 0 }) public readonly amount!: number;
	@Prop({ default: '' }) public readonly override!: string;
	@Prop({ default: '' }) public readonly footer!: string;
	@Prop({ default: true }) public readonly isShowFooter!: boolean;
    @Prop({ default: ''}) public readonly invoiceCount?: number;

	public get numberText() {
		return !this.isShowPercentage
				? BaseReport.shortenNumber(Math.ceil(this.amount)).toLocaleString()
				: BaseReport.shortenNumber(this.amount).toFixed(1) + ' %';
	}

	public get isReceiveCardsQuantity() {
		if (this.amount !== undefined) {
			return true;
		} else {
			return false;
		}
	}

	public get currencySymbol() {
		return gdbx.currencySymbol;
	}
}
