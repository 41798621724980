
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import TableFilterable from '@/components/reports-v2/components/filterables/TableFilterable.vue';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import { PermissionsGroup, ValueText } from '@/store/models.def';
import { TableItemFormatter } from '@/components/reports-v2/components/elements/charts/helpers/tableItemFormatter';
import SupplierListFilter from '@/components/reports-v2/components/filters/SupplierListFilter.vue';
import settingx from '@/store/modules/settingx';
import IncludeCreditNoteToggle from '@/components/IncludeCreditNoteToggle.vue';
import MultiSelect from 'primevue/multiselect';
import gdbx from '@/store/modules/gdbx';
import moment from 'moment';

@Component({
	components: {
		TableFilterable,
		SupplierListFilter,
		IncludeCreditNoteToggle,
		MultiSelect,
	},
})
export default class StockItemPurchaseBySupplierTable extends FilterWidget {
	public get permissionIds(): PermissionsGroup[] {
		return ['stocks', 'purchases'];
	}

	public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
	> = ['dateRange', 'stockItems', 'suppliers'];

	public tableItems: any[] = [];
	public tableFields: any[] = [];
	public selectedSuppliersList: string[] = [];

	@Watch('options', { immediate: true })
	public onOptionsChanged(newVal: ValueText[]) {
		if (this.selectedSuppliersList.length === 0) {
			this.selectedSuppliersList = newVal.map((item) => item.value);
		}
	}

	public get options() {
		return gdbx.allSuppliers;
	}

	public get dateFormatted() {
		return this.selectedDateRange;
	}

	public get exportFileName() {
		const formattedDateRange = [
			moment(this.selectedDateRange[0]).format('DD MMM YY'),
			moment(this.selectedDateRange[1]).format('DD MMM YY'),
		];
		return (
			'Stock Item Purchase By Supplier' +
			'_' +
			formattedDateRange[0] +
			' to ' +
			formattedDateRange[1]
		);
	}

	public get includeCreditNote() {
		return settingx.now.includeCreditNote;
	}

	public get expensiveHook() {
		const {
			selectedSuppliersList,
			selectedDateRange,
			selectedSuppliers,
			selectedStockItems,
			includeCreditNote,
		} = this;
		return JSON.stringify([
			selectedSuppliersList,
			selectedDateRange,
			selectedSuppliers,
			selectedStockItems,
			includeCreditNote,
		]);
	}

	public async expensiveCalc() {
		const ref = FilteredDatabase.ref('purchases')
			.suppliers(this.selectedSuppliersList)
			.stockItems(this.selectedStockItems)
			.dateRange(this.selectedDateRange);

		let pa = 0.2;
		let pb = 0;

		const itemList = [...(await (await ref.get()).getSet('itemCode'))];

		const supplierList = [...(await (await ref.get()).getSet('code'))];

		const purchaseRef = FilteredDatabase.ref('purchases')
			.suppliers(supplierList)
			.stockItems(itemList)
			.dateRange(this.selectedDateRange);

		const creditNoteRef = FilteredDatabase.ref('purchaseReturn')
			.suppliers(supplierList)
			.stockItems(itemList)
			.dateRange(this.selectedDateRange);

		pb += pa;
		pa = 0.2;

		const purchaseAmountDD = await this._loadDimensionByFilters(
			purchaseRef,
			'stockItems',
			pa,
			pb,
			'amount',
		);

		pb += pa;
		pa = 0.2;

		const purchaseQuantityDD = await this._loadDimension(
			purchaseRef,
			purchaseAmountDD,
			pa,
			pb,
			'quantity',
		);

		pb += pa;
		pa = 0.2;

		const creditNotesAmountDD = await this._loadDimensionByFilters(
			creditNoteRef,
			'stockItems',
			pa,
			pb,
			'amount',
		);

		pb += pa;
		pa = 0.2;

		const creditNotesQuantityDD = await this._loadDimensionByFilters(
			creditNoteRef,
			'stockItems',
			pa,
			pb,
			'quantity',
		);

		this.tableFields = [
			{ key: 'item_code', sortable: true },
			{ key: 'item_description', sortable: true },
			{ key: 'amount', sortable: true, formatter: TableItemFormatter.currency },
			{
				key: 'quantity',
				sortable: true,
				formatter: TableItemFormatter.basic,
			},
		];

		let result = purchaseAmountDD.map((dd, index) => {
			const object = {
				item_code: dd.value[0],
				item_description: dd.text,
				amount: this.includeCreditNote
					? dd.sum - creditNotesAmountDD[index].sum
					: dd.sum,
				quantity: this.includeCreditNote
					? purchaseQuantityDD[index].sum - creditNotesQuantityDD[index].sum
					: purchaseQuantityDD[index].sum,
			};

			return object;
		});

		result = result.filter((item) => item.amount !== 0 && item.quantity !== 0);

		this.tableItems = result;

		this.saveHistory('tableItems', 'tableFields');
	}

	public onHovering(value: boolean, filterId: string) {
		this.$emit('hovering', value, filterId);
	}
	public onEditing(value: boolean, filterId: string) {
		this.$emit('editing', value, filterId);
	}
}
