
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import TableFilterable from '@/components/reports-v2/components/filterables/TableFilterable.vue';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import { PermissionsGroup, SalesOrderDetailCard } from '@/store/models.def';
import { TableItemFormatter } from '@/components/reports-v2/components/elements/charts/helpers/tableItemFormatter';
import { addComma } from '@/util/number';
import gdbx from '@/store/modules/gdbx';
import moment from 'moment';
import TableCollapseToggle from '@/components/TableCollapseToggle.vue';

@Component({
	components: {
		TableFilterable,
		TableCollapseToggle,
	},
})
export default class PurchaseOrderTrackingTable extends FilterWidget {
	@Prop({ default: () => [] }) public selectedAreas!: string[];

	public get permissionIds(): PermissionsGroup[] {
		return ['purchases'];
	}

	public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
	> = [];

	public validityDays: number = 30;

	public tableItems: Array<{
		doc_no: string;
		doc_date: number;
		customer: string;
		amount: number;
		osAmount: number;
		item: number;
		osItem: number;
		items: any;
	}> = [];
	public tableFields: any[] = [];
	public detailFields: any[] = [];
	public detailFields2: any[] = [];

	public amountSum: number = 0;
	public osAmountSum: number = 0;
	public itemSum: number = 0;
	public osItemSum: number = 0;

	public periodTotalData: any[] = [];
	public periodTotalFields: any[] = [
		{
			key: 'amount',
			label: 'Total Amount',
		},
		{
			key: 'os_amount',
			label: 'Total O/S Amount',
		},
		{
			key: 'item',
			label: 'Total Item',
		},
		{
			key: 'os_item',
			label: 'Total O/S Item',
		},
	];

	public infoModal = {
		id: 'info-modal2',
		index: 0,
		title: '',
		amount: '',
		docDate: '',
		osAmount: '',
		items: [] as any[],
	};

	public format(
		value: number,
		decimal: number = 2,
		forceDecimal: boolean = true,
	) {
		return addComma(value, decimal, forceDecimal);
	}

	public info(item, index, title, amount, osAmount, link) {
		this.infoModal.title = title + ' ' + '( ' + item.items[0].customer + ' )';
		this.infoModal.index = index;
		this.infoModal.amount = this.format(amount);
		this.infoModal.osAmount = this.format(osAmount);
		this.infoModal.docDate = item.items[0].docDate;
		this.infoModal.items = item.items;
		this.$root.$emit('bv::show::modal', this.infoModal.id, link);
	}

	public resetInfoModal() {
		this.infoModal.title = '';
		this.infoModal.items = [];
	}

	public get currency() {
		return gdbx.currencySymbol;
	}

	public get dateFormatted() {
		return [this.selectedAsOfDate];
	}

	public get expensiveHook() {
		const {
			selectedAgents,
			selectedCustomers,
			selectedAsOfDate,
			selectedAreas,
		} = this;
		return JSON.stringify([
			selectedAgents,
			selectedCustomers,
			selectedAsOfDate,
			selectedAreas,
		]);
	}

	public async expensiveCalc() {
		const globalSupplierRef = FilteredDatabase.ref('globalSuppliers')
			.suppliers(this.selectedSuppliers)
			.includes('area', this.selectedAreas);

		const filteredAreaCustomers = [
			...(await (await globalSupplierRef.get()).getSet('code')),
		];

		const salesOrderRef = FilteredDatabase.ref('purchaseOrder')
			.suppliers(
				filteredAreaCustomers.length > 0
					? filteredAreaCustomers
					: this.selectedCustomers,
			)
			.agents(this.selectedAgents)
			.dateAsOf(this.selectedAsOfDate);

		const salesOrderCards: SalesOrderDetailCard[] = await (
			await salesOrderRef.get()
		).getCards();

		const result = {};

		for (const item of salesOrderCards) {
			if (!result[item.docNo]) {
				result[item.docNo] = [];
			}
			result[item.docNo].push(item);
		}

		const beforeResult: any[] = [];

		for (const item in result) {
			if (result.hasOwnProperty(item)) {
				const object = {
					doc_no: '',
					doc_date: '',
					customer: '',
					amount: 0,
					osAmount: 0,
					item: 0,
					osItem: 0,
					items: [] as any[],
				};

				const cards = result[item] as SalesOrderDetailCard[];

				const itemObject: Array<{
					sequence: number;
					customer: string;
					item: string;
					amount: number;
					docDate: string;
					osAmount: number;
					quantity: number;
					osQuantity: number;
					_rowVariant: {};
				}> = [];

				const detailObject = cards.map((i) => ({
					sequence: i.sequence,
					customer: gdbx.customerNames[i.code],
					docDate: moment(i.date).format('DD MMM YYYY'),
					item: gdbx.stockItemNames[i.itemCode],
					amount: i.amount,
					osAmount:
						i.outstandingQuantity === 0
							? 0
							: (i.amount / i.quantity) * i.outstandingQuantity,
					quantity: i.quantity,
					osQuantity: i.outstandingQuantity,
					_rowVariant: i.outstandingQuantity > 0 ? 'top-five' : '',
				}));

				itemObject.push(...detailObject);

				const amount = cards
					.map((card) => card.amount)
					.reduce((a, b) => a + b, 0);
				const quantity = [...new Set(cards.map((card) => card.sequence))]
					.length;
				const osAmount = cards
					.map((card) =>
						card.outstandingQuantity === 0
							? 0
							: (card.amount / card.quantity) * card.outstandingQuantity,
					)
					.reduce((a, b) => a + b, 0);

				const osQuantity = [
					...new Set(
						cards
							.filter((card) => card.outstandingQuantity > 0)
							.map((card) => card.sequence),
					),
				].length;

				object.doc_no = item;
				object.doc_date = TableItemFormatter.date3(cards[0].date);
				object.customer = gdbx.customerNames[cards[0].code];
				object.amount = amount;
				object.osAmount = osAmount;
				object.item = quantity;
				object.osItem = osQuantity;
				object.items = itemObject;

				beforeResult.push(object);
			}
		}

		this.tableItems = [...beforeResult.filter((item) => item.osItem > 0)];

		// this.tableItems = this.tableItems.filter((item) => item.osItem > 0);

		this.amountSum = beforeResult.reduce((a, b) => a + b.amount, 0);
		this.osAmountSum = beforeResult.reduce((a, b) => a + b.osAmount, 0);
		this.itemSum = beforeResult.reduce((a, b) => a + b.item, 0);
		this.osItemSum = beforeResult.reduce((a, b) => a + b.osItem, 0);

		this.periodTotalData = [
			{
				amount:
					this.currency + ' ' + addComma(this.amountSum, gdbx.numDecimal, true),
				os_amount:
					this.currency +
					' ' +
					addComma(this.osAmountSum, gdbx.numDecimal, true),
				item: this.itemSum,
				os_item: this.osItemSum,
			},
		];

		this.tableFields = [
			{ key: 'doc_no', sortable: true },
			{
				key: 'doc_date',
				sortable: true,
				formatter: TableItemFormatter.date3,
			},
			{
				key: 'customer',
				sortable: true,
			},
			{
				key: 'amount',
				label: 'Amt',
				sortable: true,
				formatter: TableItemFormatter.currency,
			},
			{
				key: 'osAmount',
				label: 'O/S Amount',
				sortable: true,
				formatter: TableItemFormatter.currency,
			},
			{
				key: 'item',
				sortable: true,
			},
			{
				key: 'osItem',
				label: 'O/S Item',
				sortable: true,
			},
		];

		this.detailFields = [
			{ key: 'sequence', label: 'Seq', sortable: true },
			{
				key: 'item',
				sortable: true,
			},
			{
				key: 'amount',
				label: 'Amt',
				sortable: true,
				formatter: TableItemFormatter.currency,
			},
			{
				key: 'osAmount',
				label: 'O/S Amt',
				sortable: true,
				formatter: TableItemFormatter.currency,
			},
			{
				key: 'quantity',
				label: 'Qty',
				sortable: true,
			},
			{
				key: 'osQuantity',
				label: 'O/S Qty',
				sortable: true,
			},
		];

		this.saveHistory('tableItems', 'tableFields');
	}
}
