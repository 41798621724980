
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import TreeMapFilterable from '@/components/reports-v2/components/filterables/TreeMapFilterable.vue';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import SelectFilter from '@/components/reports-v2/components/filters/SelectFilter.vue';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import {
	CustomerSupplierDbCard,
	DimensionData,
	PermissionsGroup,
	ValueText,
} from '@/store/models.def';
import * as echarts from 'echarts';

@Component({
	components: {
		TreeMapFilterable,
		SelectFilter,
	},
})
export default class PurchaseAnalysisTreeMap extends FilterWidget {
	public get permissionIds(): PermissionsGroup[] {
		return ['purchases'];
	}

	public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
	> = ['dateRange'];

	public result: any = [];
	public series: echarts.ECharts[] | any = [];

	public selectedGrouping: any = null;

	public get groupingList(): Array<ValueText<string>> {
		const list = [
			{ text: 'Stock Items', value: 'stockItems' },
			{ text: 'Stock Group', value: 'stockGroup' },
			{ text: 'Sales Agent', value: 'salesAgent' },
			{ text: 'Supplier', value: 'supplier' },
			{ text: 'Supplier Categories', value: 'supplierCategories' },
		];

		return list;
	}

	public get dateFormatted() {
		return this.selectedDateRange;
	}

	public get expensiveHook() {
		const {
			selectedDateRange,
			selectedAsOfDate,
			selectedAgents,
			selectedSuppliers,
			selectedGrouping,
		} = this;
		return JSON.stringify([
			selectedDateRange,
			selectedAsOfDate,
			selectedAgents,
			selectedSuppliers,
			selectedGrouping,
		]);
	}

	public async expensiveCalc() {
		const purchaseRef = FilteredDatabase.ref('purchases')
			.dateRange(this.selectedDateRange)
			.suppliers(this.selectedSuppliers);

		const supplierList = [...(await (await purchaseRef.get()).getSet('code'))];
		const itemCodeList = [
			...(await (await purchaseRef.get()).getSet('itemCode')),
		];

		const purchaseCloneRef = FilteredDatabase.ref('purchases')
			.dateRange(this.selectedDateRange)
			.stockItems(itemCodeList)
			.suppliers(supplierList);

		const cnRef = FilteredDatabase.ref('purchaseReturn')
			.dateRange(this.selectedDateRange)
			.stockItems(itemCodeList)
			.suppliers(supplierList);

		const tradeIvDnRef = FilteredDatabase.ref('supplierTrades')
			.dateRange(this.selectedDateRange)
			.suppliers(supplierList)
			.stockItems(itemCodeList)
			.includes('docType', ['PI', 'SD']);

		const tradeCNRef = FilteredDatabase.ref('supplierTrades')
			.dateRange(this.selectedDateRange)
			.suppliers(supplierList)
			.stockItems(itemCodeList)
			.includes('docType', ['SC']);

		const globalRef =
			FilteredDatabase.ref('globalSuppliers').suppliers(supplierList);

		const globalCards: CustomerSupplierDbCard[] = await (
			await globalRef.get()
		).getCards();

		const supplierAreaList = {};

		for (const global of globalCards) {
			if (!supplierAreaList[global.areaDesc]) {
				supplierAreaList[global.areaDesc] = [];
			}
			supplierAreaList[global.areaDesc].push(global.code);
		}

		let pa = 0.3;
		let pb = 0;

		let tradeDD: DimensionData[] = [];
		let tradeCNDD: DimensionData[] = [];

		const supplierPurchaseDD = await this._loadDimensionByFilters(
			tradeIvDnRef,
			'suppliers',
			pa,
			pb,
			'amount',
		);

		pb += pa;
		pa = 0.3;

		const supplierCNDD = await this._loadDimensionByFilters(
			tradeCNRef,
			'suppliers',
			pa,
			pb,
			'amount',
		);

		pb += pa;
		pa = 0.3;

		switch (this.selectedGrouping) {
			case 'supplierCategories':
				tradeDD = await this._loadDimensionByFilters(
					tradeIvDnRef,
					'supplierCategory',
					pa,
					pb,
					(reff, paa, pbb) =>
						this._loadDimension(reff, supplierPurchaseDD, paa, pbb, 'amount'),
				);
				tradeCNDD = await this._loadDimensionByFilters(
					tradeCNRef,
					'supplierCategory',
					pa,
					pb,
					(reff, paa, pbb) =>
						this._loadDimension(reff, supplierCNDD, paa, pbb, 'amount'),
				);
				break;
			case 'salesAgent':
				tradeDD = await this._loadDimensionByFilters(
					tradeIvDnRef,
					'agents',
					pa,
					pb,
					'amount',
				);
				tradeCNDD = await this._loadDimensionByFilters(
					tradeCNRef,
					'agents',
					pa,
					pb,
					'amount',
				);
				break;
			case 'supplier':
				tradeDD = supplierPurchaseDD;
				tradeCNDD = supplierCNDD;
				break;
			case 'stockGroup':
				tradeDD = await this._loadDimensionByFilters(
					purchaseCloneRef,
					'stockGroup',
					pa,
					pb,
					(reff, paa, pbb) =>
						this._loadDimensionByFilters(
							reff,
							'stockItems',
							paa,
							pbb,
							'amount',
						),
				);
				tradeCNDD = await this._loadDimensionByFilters(
					cnRef,
					'stockGroup',
					pa,
					pb,
					(reff, paa, pbb) =>
						this._loadDimensionByFilters(
							reff,
							'stockItems',
							paa,
							pbb,
							'amount',
						),
				);
				break;
			default:
				tradeDD = await this._loadDimensionByFilters(
					purchaseCloneRef,
					'stockItems',
					pa,
					pb,
					'amount',
				);
				tradeCNDD = await this._loadDimensionByFilters(
					cnRef,
					'stockItems',
					pa,
					pb,
					'amount',
				);
		}

		this.generateLoadingText(1);

		this.result = [];

		tradeDD.forEach((trade, index) => {
			const subDD = trade.subDimension;
			this.result.push({
				name: trade.text,
				value: trade.sum - tradeCNDD[index].sum,
				color: [
					'#8975ea',
					'#acbeff',
					'#accdff',
					'#acdaff',
					'#cdc8f9',
					'#c7b5e2',
				],
				colorSaturation: [0.9, 0.4],
				children: subDD
					? subDD.map((subdd) => ({
							name: subdd.text,
							value: subdd.sum,
							color: [
								'#8975ea',
								'#acbeff',
								'#accdff',
								'#acdaff',
								'#cdc8f9',
								'#c7b5e2',
							],
							colorSaturation: [0.9, 0.4],
					  }))
					: [],
			});
		});

		this.result = this.result.filter((item) => item.value > 0);

		this.series = [];

		const stock = {
			name: 'Purchase Analysis',
			type: 'treemap',
			roam: 'move',
			width: '100%',
			// drillDownIcon: '👆🏻',
			drillDownIcon: '',
			// leafDepth: 1,
			label: {
				color: '#000',
			},
			itemStyle: {
				gapWidth: 1,
				borderWidth: 1,
			},
			levels: [
				{
					itemStyle: {
						// borderColor: '#555',
						borderWidth: 2,
						gapWidth: 2,
					},
					upperLabel: {
						show: false,
						fontWeight: 'bold',
						color: '#000',
					},
					emphasis: {
						itemStyle: {
							borderWidth: 0,
							gapWidth: 2,
						},
						upperLabel: {
							show: true,
							fontWeight: 'bold',
							color: '#000',
						},
					},
				},
				{
					colorSaturation: [0.3, 0.6],
					itemStyle: {
						borderColorSaturation: 0.7,
						gapWidth: 2,
						borderWidth: 2,
					},
					upperLabel: {
						show: true,
						color: '#000',
						fontWeight: 'bold',
					},
					emphasis: {
						itemStyle: {
							borderWidth: 0,
							gapWidth: 1,
						},
						upperLabel: {
							show: true,
							color: '#000',
							fontWeight: 'bold',
						},
					},
				},
				{
					itemStyle: {
						// borderColor: '#555',
						gapWidth: 1,
						borderWidth: 1,
					},
					upperLabel: {
						show: true,
						color: '#000',
						fontWeight: 'bold',
					},
					emphasis: {
						itemStyle: {
							borderWidth: 0,
							gapWidth: 1,
						},
						upperLabel: {
							show: true,
							color: '#000',
							fontWeight: 'bold',
						},
					},
				},
			],
			data: this.result,
		};

		this.series = [stock];

		this.saveHistory('result', 'series');
	}

	public onHovering(value: boolean, filterId: string) {
		this.$emit('hovering', value, filterId);
	}
	public onEditing(value: boolean, filterId: string) {
		this.$emit('editing', value, filterId);
	}
}
