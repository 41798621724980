
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import StickyFilterContainer from '@/components/reports-v2/components/pages/StickyFilterContainer.vue';
import PurchaseAnalysisTreeMap from '@/components/reports-v2/components/codedWidgets/purchase/PurchaseAnalysisTreeMap.vue';
import PurchaseOrderTrackingTable from '@/components/reports-v2/components/codedWidgets/purchase/PurchaseOrderTrackingTable.vue';
import userx from '@/store/modules/userx';
import router from '@/router';
import moment from 'moment';
import DateRangeFilter from '@/components/reports-v2/components/filters/DateRangeFilter.vue';
import SelectFilter from '@/components/reports-v2/components/filters/SelectFilter.vue';
import gdbx from '@/store/modules/gdbx';
import LastSync from '@/components/LastSync.vue';
import StockItemPurchaseBySupplierTable from '@/components/reports-v2/components/codedWidgets/stock/StockItemPurchaseBySupplierTable.vue';
import BasePageContainer from '../BasePageContainer.vue';
// tslint:disable:max-line-length
import PurchasePeriodComparisonLineChart from '@/components/reports-v2/components/codedWidgets/purchase/PurchasePeriodComparisonLineChart.vue';
import TotalPurchaseDisplayer from '@/components/reports-v2/components/codedWidgets/purchase/TotalPurchaseDisplayer.vue';
import TotalCreditNoteDisplayer from '@/components/reports-v2/components/codedWidgets/purchase/TotalCreditNoteDisplayer.vue';
import TotalPurchaseOrderDisplayer from '@/components/reports-v2/components/codedWidgets/purchase/TotalPurchaseOrderDisplayer.vue';

@Component({
	components: {
		StickyFilterContainer,
		DateRangeFilter,
		PurchaseAnalysisTreeMap,
		LastSync,
		StockItemPurchaseBySupplierTable,
		SelectFilter,
		PurchaseOrderTrackingTable,
		TotalPurchaseDisplayer,
		TotalCreditNoteDisplayer,
		TotalPurchaseOrderDisplayer,
		PurchasePeriodComparisonLineChart,
	},
})
export default class PurchaseOverview extends BasePageContainer {
	public selectedDay: number = moment().endOf('day').valueOf();
	public selectedDateRange: [number, number] = [
		moment().startOf('month').valueOf(),
		moment().endOf('day').valueOf(),
	];

	public mounted() {
		this.track();
	}

	public track() {
		this.$ga.set('userId', userx.user!.uid + '_' + userx.user!.email);
		this.$ga.page(router);
	}

	public get selectedAsOfDate() {
		return moment(this.selectedDay).endOf('month').valueOf();
	}

	public get selectedAgents() {
		return gdbx.allAgents.map((vt) => vt.value);
	}

	public get selectedStockItems() {
		return gdbx.allStockItems.map((vt) => vt.value);
	}

	public get selectedSuppliers() {
		return gdbx.allSuppliers.map((vt) => vt.value);
	}

	public onHovering(value: boolean, filterId: string) {
		this.$emit('hovering', value, filterId);
	}
	public onEditing(value: boolean, filterId: string) {
		this.$emit('editing', value, filterId);
	}
}
