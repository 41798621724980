
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import BaseReport from '../BaseReport.vue';

@Component({
	components: {},
})
export default class BasePageContainer extends BaseReport {
	public get pageIndex() {
		const segs = this.$route.path.split('/');
		return Number(segs[segs.length - 1]);
	}
	@Watch('pageIndex')
	public onPageIndexChange() {
		window.scrollTo(0, 0);
	}

}
