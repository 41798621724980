
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import moment, { Moment } from 'moment';
import BaseFilterableContainer from './BaseFilterableContainer.vue';
import BaseFilterable from './BaseFilterable.vue';
import DataInfo from '@/components/reports-v2/components/elements/DataInfo.vue';
import AmountDisplayer from '@/components/reports-v2/components/elements/displayers/AmountDisplayer.vue';

@Component({
	components: {
		AmountDisplayer,
		BaseFilterable,
		DataInfo,
	},
})
export default class AmountDisplayerFilterable extends BaseFilterableContainer {
	@Prop({ default: false }) public readonly isShowCurrencySymbol!: boolean;
	@Prop({ default: false }) public readonly isShowPercentage!: boolean;
	@Prop({ default: true }) public readonly isShowFooter!: boolean;
	@Prop({ default: 0 }) public readonly amount!: number;
	@Prop({ default: '' }) public readonly override!: string;
    @Prop({ default: ''}) public readonly invoiceCount?: number;

	public get hasFooterSlot() {
		return !!this.$slots.topRight;
	}
}
