
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import AmountDisplayerFilterable from '@/components/reports-v2/components/filterables/AmountDisplayerFilterable.vue';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import { PermissionsGroup } from '@/store/models.def';

@Component({
	components: {
		AmountDisplayerFilterable,
	},
})
export default class AvgValuePerTransactionNumberDisplayer extends FilterWidget {
	public get permissionIds(): PermissionsGroup[] {
		return ['purchases'];
	}

	public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
	> = ['dateRange'];

	public amount: number = 0;

	public get expensiveHook() {
		const { selectedDateRange } = this;
		return JSON.stringify([selectedDateRange]);
	}

	public async expensiveCalc() {
		const purchaseRef = FilteredDatabase.ref('purchases').dateRange(
			this.selectedDateRange,
		);

		const CNRef = FilteredDatabase.ref('purchaseReturn').dateRange(
			this.selectedDateRange,
		);

		const amount = await (await purchaseRef.get()).getSum('amount');
		const creditNote = await (await CNRef.get()).getSum('amount');

		this.amount = amount - creditNote;

		this.saveHistory('amount');
	}
}
