var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('TableFilterable',{attrs:{"currentFocusingFilterId":_vm.currentFocusingFilterId,"filterIds":_vm.filterIds,"loading":_vm.loading,"loadingText":_vm.loadingText,"date":_vm.dateFormatted,"title":"Pending Purchase Order","items":_vm.tableItems,"fields":_vm.tableFields,"firstLevelDetailFields":_vm.detailFields,"sortBy":"doc_date","sort-desc":false,"sticky-header":"500px","permissionIds":_vm.permissionIds,"sortField":"conversion_rate","expandMode":"dialog","dialogFields":_vm.detailFields,"dialogEntryKey":"doc_no"},scopedSlots:_vm._u([{key:"cell(doc_no)",fn:function(data){return [_c('div',{staticClass:"d-flex flex-row align-items-center"},[_c('b-link',{on:{"click":function($event){return _vm.info(
						data.item,
						data.index,
						data.value,
						data.item.amount,
						data.item.osAmount,
						$event.target,
					)}}},[_vm._v(_vm._s(data.item.doc_no))])],1)]}}])},[[_c('TableCollapseToggle',{attrs:{"tableDatas":_vm.periodTotalData,"tableFields":_vm.periodTotalFields,"buttonName":"Total","visible":true}})],_c('b-modal',{attrs:{"centered":"","size":"xl","id":_vm.infoModal.id},on:{"hide":_vm.resetInfoModal},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('b',{staticClass:"d-flex justify-content-center"},[_vm._v(_vm._s(_vm.infoModal.title))])]},proxy:true},{key:"default",fn:function(){return [_c('b-table',{staticClass:"text-left",attrs:{"items":_vm.infoModal.items,"fields":_vm.detailFields,"hover":"","responsive":"","outlined":"","striped":"","show-empty":"","head-variant":"dark","sticky-header":"500px"}})]},proxy:true},{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"w-100"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"row"},[_c('span',{staticClass:"pr-2 font-weight-bold text-uppercase text-info"},[_vm._v("Doc Date : ")])]),_c('div',{staticClass:"row"},[_c('span',{staticClass:"pr-1 font-weight-bold"},[_vm._v(_vm._s(_vm.infoModal.docDate))])])]),_c('div',{staticClass:"col"},[_c('div',{staticClass:"row"},[_c('span',{staticClass:"pr-2 font-weight-bold text-uppercase text-info"},[_vm._v("Total Amount: ")])]),_c('div',{staticClass:"row"},[_c('span',{staticClass:"pr-1 font-weight-bold"},[_vm._v(_vm._s(_vm.currency))]),_c('span',{staticClass:"pr-1 font-weight-bold"},[_vm._v(_vm._s(_vm.infoModal.amount))])])]),_c('div',{staticClass:"col"},[_c('div',{staticClass:"row"},[_c('span',{staticClass:"font-weight-bold text-uppercase text-info"},[_vm._v("O/S Amt Total : ")])]),_c('div',{staticClass:"row"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.currency))]),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.infoModal.osAmount))])])])])])]},proxy:true}])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }